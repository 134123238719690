<template>
  <div class="login_main_1">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <form method="post" @submit.prevent="Login">
            <div class="login_head">
              <h2>LOGIN</h2>
              <div :class="{ invalid: $v.loginData.email.$error }">
                <label class="mb-1">
                  <h5 class="mb-0 text-sm">Email Address</h5>
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="youremail@gmail.com"
                  @blur="$v.loginData.email.$touch()"
                  v-model.trim="loginData.email"
                  required
                />
                <div v-if="$v.loginData.email.$error">
                  <p
                    class="error_level"
                    v-if="!$v.loginData.email.required"
                  >Email field is required.</p>
                  <p
                    class="error_level"
                    v-if="!$v.loginData.email.minLength"
                  >At least 6 charecter email.</p>
                  <p class="error_level" v-if="!$v.loginData.email.email">Please enter valid email.</p>
                </div>
              </div>
              <div class="mt-3" :class="{ invalid: $v.loginData.password.$error }">
                <label class="mb-1">
                  <h5 class="mb-0 text-sm">Password</h5>
                </label>
                <input
                  type="password"
                  name="password"
                  placeholder=".........."
                  @blur="$v.loginData.password.$touch()"
                  v-model.trim="loginData.password"
                  required
                />
                <div v-if="$v.loginData.password.$error">
                  <p
                    class="error_level"
                    v-if="!$v.loginData.password.required"
                  >Password field is required.</p>
                  <p
                    class="error_level"
                    v-if="!$v.loginData.password.minLength"
                  >At least 6 charecter password.</p>
                </div>
              </div>
              <a href="javascript:;" class="forget_password">Forget your password ?</a>
              <div class="login_main1">
                <button type="submit">LOGIN</button>
              </div>
              <div class="text-justify">
                <p class="error_level">{{eventMessage}}</p>
              </div>
            </div>
          </form>
        </div>

        <div class="offset-md-1 col-md-4">
          <div class="login_person_img">
            <img src="assets/img/man.png" />
          </div>
        </div>
        <!-- <div class="col-md-3">
          <div class="login_main1_register">
            <h2>If you have not registered till now click on the button below for registration !</h2>
            <div class="login_main1">
              <a href="javascript:;" class="mt-0">Register</a>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { required, email, minLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["apiRoot", "processing", "eventMessage"]),
  },
  data() {
    return {
      error: false,
      loginData: {
        email: "",
        password: "",
      },
    };
  },
  validations: {
    loginData: {
      email: {
        required,
        email,
        minLength: minLength(6),
      },
      password: {
        required,
        minLength: minLength(6),
      },
    },
  },
  methods: {
    Login() {
      if (!this.$v.$invalid) {
        this.$store.commit("enableProcessing");
        this.$http
          .post(`${this.apiRoot}api/login`, this.loginData)
          .then((response) => {
            this.$store.commit("disableProcessing");
            if (response.body.error_message) {
              this.$store.commit(
                "setEventMessage",
                response.body.error_message
              );
            } else {
              this.$store.commit(
                "setEventMessage",
                response.data.success_message
              );
              this.checkAuthUser(response.data);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("disableProcessing");
            this.$store.commit("setEventMessage", err.body.error);
          });
      } else {
        console.log("something went wrong");
        this.$store.commit(
          "setEventMessage",
          `Something went wrong. please try again later.`
        );
      }
    },
    checkAuthUser(auth) {
      if (!auth.data.user.email_verified_at) {
        this.$store.commit(
          "setEventMessage",
          "This credential is not verified yet. Please contact to administer."
        );
      } else if (auth.data.user.deleted_at) {
        this.$store.commit(
          "setEventMessage",
          "This credential is temporary blocked. Please contact to administer."
        );
      } else if (auth.data.user.status == "0") {
        this.$store.commit(
          "setEventMessage",
          "This credential is not approved yet. Please contact to administer."
        );
      } else if (auth.data.user.status == "2") {
        this.$store.commit(
          "setEventMessage",
          "This credential is deactivated. Please contact to administer."
        );
      } else {
        this.setAuthData(auth);
      }
    },
    setAuthData(auth) {
      localStorage.setItem("authData", JSON.stringify(auth.data));
      localStorage.setItem("token", auth.token);
      localStorage.setItem("sidebar",JSON.stringify(auth.sidebar));
      localStorage.setItem(
        "permissions",
        JSON.stringify(auth.data.permissions)
      );
      localStorage.setItem(
        "ideal_time",
        auth.ideal_time
      );
      this.commitAuthData(auth);
    },
    commitAuthData(auth) {
      this.$store.commit("setToken", auth.token);
      this.$store.commit("setAuthData", auth.data);
      this.$router.push("/home");
      location.reload();
    },
  },
  destroyed() {
    this.$store.commit("setEventMessage", "");
  },
};
</script>
